import React, {useEffect} from 'react';
import {Link as RouterLink, useHistory, useLocation} from 'react-router-dom';
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';

import {headerTheme} from "../../theme";
import {useDispatch, useSelector} from "react-redux";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import * as actions from "../../actions/pages";
import ReactHtmlParser from "react-html-parser";
import {PAGES_SLUGS} from "../../constants";
import Seo from "../../components/seo/Seo";
import {GTM_GETOFFER_TY_PARAMS, GTM_GETOFFER_TY_PARAMS_22, withGtmTraking} from "../../constants/tracking";
import {dealershipFacetName} from "../../constants/algolia";
import GetOfferSliderParent from "../GetOfferPage/GetOfferSliderParent";

/**
 * Check comments and history to revert to pre-blue weeks
 * button class was removed and replaced with a pt-8 box; keep it
 */
const FormTyModal = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const pageData = useSelector(state => _get(state.pages.data, PAGES_SLUGS.OFFER_THANK_YOU, {}));
    const loading = useSelector(state => state.pages.request);
    const error = useSelector(state => state.pages.error);
    const currentDealer = useSelector(state => state.dealer.data);

    const buttonLink = (() => {
        if (_get(location, 'state.to', null)) return location.state.to;

        if(!!currentDealer) {
            return `/${PAGES_SLUGS.DEALER}?${dealershipFacetName}=${currentDealer}`
        } else {
            return _get(pageData, "data.content.url", '/')
        }
    })();

    useEffect(() => {
        window.scrollTo(0, 0)
        if (_isEmpty(pageData)) {
            dispatch(actions.fetchPage(PAGES_SLUGS.OFFER_THANK_YOU))
        }
    }, [])

    useEffect(() => {
        if (withGtmTraking === 'true') {
            window.dataLayer.push(GTM_GETOFFER_TY_PARAMS);
            // window.dataLayer.push(GTM_GETOFFER_TY_PARAMS_22);
        }

        if (process.env.REACT_APP_ENABLE_TRAKING_SCRIPTS === 'true') {
            window.fbq('track', '372626333464859', 'Lead')
        }
    }, [])


    return (
        <>
            {!_isEmpty(_get(pageData, 'data.meta_data', {})) && <Seo data={_get(pageData, 'data.meta_data', {})}/>}
            <div className={classes.root}>
                {!loading && <div className={classes.body}
                                  // style={{"--background-image": `url(${_get(pageData, 'data.content.image', "")})`}}
                >
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        height={"100%"}
                        width={"100%"}
                    >
                        <Box display={"flex"}
                             justifyContent={"center"}
                             alignItems={"center"}
                             flexDirection={"column"}
                             px={2}
                        >
                            <Typography component={'div'} align={"center"}
                                        className={classes.title}>{ReactHtmlParser(_get(pageData, "data.content.title", ''))}</Typography>
                            <Typography component={'div'} align={"center"}
                                        className={classes.text}>{ReactHtmlParser(_get(pageData, "data.content.content", ''))}</Typography>
                            <Box
                                display="flex"
                                flexDirection={{ xs: 'column', sm: 'row' }}
                                style={{"gap": "1.5rem"}} pt={8}>
                                {/*Temp disable for blue weeks*/}
                                {/*
                                <ThemeProvider theme={headerTheme}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        component={RouterLink}
                                        to={buttonLink}
                                        className={classes.button}
                                        size="large"
                                    >
                                        Vezi toate ofertele
                                    </Button>
                                </ThemeProvider>
                                */}
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        component={RouterLink}
                                        to={"/"}
                                        size="large"
                                    >
                                        Inapoi la pagina principala
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={RouterLink}
                                        to={`/${PAGES_SLUGS.BLUE_WEEK}`}
                                        size="large"
                                    >
                                        Vezi ofertele Blue Weeks
                                    </Button>
                            </Box>

                        </Box>


                    </Box>
                </div>}

                <GetOfferSliderParent loader={true}/>
            </div>

        </>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.35), rgba(0,0,0,0.35)), var(--background-image)`,
        backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.35), rgba(0,0,0,0.35)), var(--background-image)`,
        backgroundSize: "cover",
        backgroundPosition: "center bottom",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        // justifyContent: "center",
        justifyContent: "space-between",
        backgroundColor: "#fff"
    },
    container: {
        [theme.breakpoints.up('lg')]: {
            maxWidth: "1100px !important"
        },
    },
    dialogRoot: {
        zIndex: `${theme.zIndex.appBar - 1}!important`
    },
    body: {
        backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.35), rgba(0,0,0,0.35)), var(--background-image)`,
        backgroundSize: "cover",
        backgroundPosition: "center bottom",
        // flex: 1,
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        padding: `4rem 0`,
        [theme.breakpoints.up('sm')]: {
            padding: `6rem 0`,
        },
        [theme.breakpoints.up('md')]: {
            padding: `10rem 0`,
        },
    },
    all: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(8),
    },
    rootClose: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    icon: {
        fontSize: "14px",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
    },
    close: {
        fontSize: "24px",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
    },
    linkIcon: {
        fontSize: 10,
    },
    title: {
        // fontWeight: theme.typography.fontWeightLight,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(38),
        lineHeight: theme.typography.pxToRem(56),
        letterSpacing: "0.4",
        // color: "#fff",
    }, text: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(24),
        // color: "#fff",
    }
}));

export default FormTyModal;
