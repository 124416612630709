import React, {useState} from "react";
import {Link, Link as RouterLink, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {SwiperSlide} from 'swiper/react';
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import _map from "lodash/map";
import clsx from "clsx";
import Fade from "react-reveal/Fade";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Container from "@material-ui/core/Container";
import Icon from "@material-ui/core/Icon";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from "@material-ui/core/CircularProgress";

import {PAGES_SLUGS} from "../../constants";
import toLocale from "../../helpers/toLocale";

import SpaceBox from "../SpaceBox";
import Slider from "../sliders/Slider";
import HeaderOfferObserver from "../HeaderOfferObserver";
import getSuppotedFileType from "../../helpers/getSuppotedFileType";
import {getWishlistData, WL} from "../../helpers/localStorage";
import itemDetailsStyles from "../../theme/itemDetailsStyles";
import {addAccessoryToWishlist, removeAccessoryFromWishlist} from "../../containers/WishlistPage/actions";
import {appStyle} from "../../theme/appStyle";
import {Breadcrumb} from "../Breadcrumb";

const AccessoryDetailsSlider = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {data} = props;
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [open, setOpen] = useState(false);
    const [openRemove, setOpenRemove] = useState(false);
    const updatingWishlist = useSelector(state => state.wishlist.request);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setOpenRemove(false);
    };

    const addToWishlist = () => {
        setOpenRemove(false);
        dispatch(addAccessoryToWishlist(data.slug));
        setOpen(true);
    }

    const removeFromWishlist = () => {
        setOpen(false);
        dispatch(removeAccessoryFromWishlist(data.slug));
        setOpenRemove(true);
    }

    // Make an array with all the variants (including current) and order them alphabetically
    const variants = [..._get(data, "group_accessories", [])];
    variants.push(data)
    variants.sort(function(a, b) {
        const current = `${a.variant_name}-${a.external_cab_variant_id}`.toUpperCase();
        const next = `${b.variant_name}-${b.external_cab_variant_id}`.toUpperCase();
        return (current < next) ? -1 : (current > next) ? 1 : 0;
    });

    return (
        <React.Fragment>
            <Snackbar open={open} onClose={handleClose}
                      autoHideDuration={2000}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      className={classes.customSnackbar}
            >
                <Alert
                    onClose={handleClose}
                    action={''}
                    icon={false}
                    className={`${classes.customAlert} ${classes.alertSuccess}`}
                >
                    ✓ Adaugat cu succes in wish list
                </Alert>
            </Snackbar>
            <Snackbar open={openRemove} onClose={handleClose}
                      autoHideDuration={2000}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      className={classes.customSnackbar}
            >
                <Alert
                    onClose={handleClose}
                    action={''}
                    icon={false}
                    className={`${classes.customAlert} ${classes.alertSuccess}`}
                >
                    <Icon className={clsx("brand-icon-remove")}/> Sters cu succes in wish list
                </Alert>
            </Snackbar>
            <Fade>
                <Grid container className={classes.root}>
                    <Grid item sm={7} xs={12}
                          className={classes.lightGrey}
                    >
                        <Box
                            height={"100%"}
                            display={"flex"}
                            flexDirection={"column"}
                        >
                            <Box display={"flex"} flexDirection={'column'} flex={1} justifyContent={'center'}>
                                <Slider
                                    effect="fade"
                                    thumbs={{swiper: thumbsSwiper}}
                                    preloadImages={false}
                                    lazy={{
                                        loadPrevNext: true,
                                        preloaderClass: "preloader-skeleton"
                                    }}
                                >
                                    {!_isEmpty(_get(data, "photos", [])) && _map(_get(data, "photos", []), (item, index) => {
                                        return <SwiperSlide key={index}>
                                            <div className={clsx(classes.slide)}>
                                                {/*Disable lazyload on accessories because slide won't reset and the old picture will be viewed when changing between variations*/}
                                                {/*It's the same with cars, but it's harder to notice the bug*/}
                                                <img
                                                    src={getSuppotedFileType(_get(item, "big", []))}
                                                    className={clsx("swiper-lazy", classes.image)}/>

                                                <Skeleton variant={'rect'} width="100%" height={"100%"}
                                                          className={"preloader-skeleton"}/>
                                            </div>
                                        </SwiperSlide>
                                    })}
                                </Slider>
                            </Box>
                            {_get(data, "photos", []).length > 1 && <Box display={"block"}>
                                <Slider
                                    onSwiper={(slider) => {
                                        setThumbsSwiper(slider);
                                    }}
                                    spaceBetween={8}
                                    freeMode={true}
                                    slidesPerView={4}
                                    watchSlidesVisibility={true}
                                    watchSlidesProgress={true}
                                    centerInsufficientSlides={true}
                                    breakpoints={{
                                        500: {
                                            spaceBetween: 16,
                                            slidesPerView: 5
                                        },
                                    }}
                                >
                                    {_map(_get(data, "photos", []), (item, index) => {
                                        return <SwiperSlide key={index}>
                                            <div className={classes.thumbSlide}>
                                                <img src={getSuppotedFileType(_get(item, "thumb", []))}
                                                     className={clsx(classes.thumbImage)}/>
                                            </div>
                                        </SwiperSlide>
                                    })}

                                </Slider>
                            </Box>
                            }
                        </Box>
                    </Grid>

                    <Grid item sm={5} xs={12}>
                        <Breadcrumb buttonName={_get(data, 'categorie', '')} name={_get(data, 'subcategorie', '')}/>
                        <div className={classes.headerContent}>
                            <Typography
                                variant="h1"
                                component="h1"
                                className={classes.title}
                            >
                                {_get(data, 'base_name', '')}
                            </Typography>
                            <Typography
                                className={classes.sku}
                                component="p"
                            >
                                # {_get(data, 'external_cab_variant_id', '')}
                            </Typography>
                            <Typography
                                component={"div"}
                                className={classes.rootPrice}>
                                Pret final: <span className={classes.price}>
                                    {toLocale(_get(data, 'final_price', ''))}€ + TVA
                                </span>
                                {data.final_price !== data.base_price && <div className={classes.oldPrice}>
                                    Redus de la: <span
                                    className={classes.oldPriceLineThrough}>{toLocale(_get(data, 'base_price', ''))}€</span> +
                                    TVA (-{`${toLocale(_get(data, "discount_percentage", ""))}`}%)
                                </div>}
                            </Typography>

                            {variants.length > 1 &&
                                <RenderVariationButtons selectedVariationID={_get(data, 'external_cab_variant_id', null)} variations={variants}/>
                            }

                            <Typography component={"p"} className={classes.shortDescription}>{data.short_description}</Typography>

                            <SpaceBox/>
                            <Button
                                component={RouterLink}
                                variant="contained"
                                color="primary"
                                classes={{root: `${classes.button} ${classes.buttonMargin}`}}
                                to={`/${PAGES_SLUGS.ACCESSORY_OFFER}/${_get(data, 'slug', '')}`}
                            >
                                Cere oferta
                            </Button>

                            {getWishlistData(WL.ACCESSORY).includes(data.slug)
                                ? <Button
                                    variant="outlined"
                                    size="large"
                                    color="primary"
                                    classes={{root: classes.buttonMargin}}
                                    startIcon={<Icon className="brand-icon-heart-fill"/>}
                                    onClick={removeFromWishlist}
                                    disabled={updatingWishlist}
                                >
                                    Sterge din wish list
                                    {updatingWishlist &&
                                    <CircularProgress size={24} className={classes.buttonProgress}/>}
                                </Button>
                                : <Button
                                    variant="outlined"
                                    size="large"
                                    color="primary"
                                    classes={{root: classes.buttonMargin}}
                                    startIcon={<Icon className="brand-icon-heart"/>}
                                    onClick={addToWishlist}
                                    disabled={updatingWishlist}
                                >
                                    Wish list
                                    {updatingWishlist &&
                                    <CircularProgress size={24} className={classes.buttonProgress}/>}
                                </Button>
                            }
                            <Box mb={3}>
                                <Typography component={"p"} variant={"caption"}>
                                    Preturile afisate sunt preturi de vanzare recomandate si pot suferi modificari in consecinta actualizarii ofertei producatorului.
                                    <br/>
                                    Serviciul de instalare nu este inclus in pretul de vanzare afisat si face obiectul ofertei dealerului autorizat. Oferta finala de pret si disponibilitatea stocului vor fi confirmate de catre dealerul autorizat ales dupa completarea datelor de contact.
                                    <br/>
                                    Pentru mai multe informatii, te rugam sa contactezi cel mai apropiat reprezentant autorizat Volvo.
                                </Typography>
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            </Fade>
            <HeaderOfferObserver className={`${classes.offerHeader}`} stickyClass={classes.showHeader}>
                <div className={classes.headerRoot}>
                    <Container>
                        <div className={classes.headerSizing}>
                            <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                height={"100%"}
                                className={classes.mobileHeaderWrapper}
                            >
                                <Typography
                                    className={classes.headerTitle}
                                >
                                    {_get(data, 'base_name', '')}
                                </Typography>
                                <Box display={"flex"}
                                     alignItems={"center"}
                                     className={classes.mobileTextWrapper}>
                                    <Box display={"flex"} className={classes.mobilePricesWrapper}><span
                                        className={`${classes.price} ${classes.headerPrice}`}>{`${toLocale(_get(data, 'final_price', ''))}€ + TVA`}</span>
                                        {data.final_price !== data.base_price && <div className={`${classes.oldPrice} ${classes.headerOldPrice}`}>
                                             <span
                                                 className={classes.oldPriceLineThrough}>{toLocale(_get(data, 'base_price', ''))}€</span> + TVA (-{`${toLocale(_get(data, "discount_percentage", ""))}`}%)
                                        </div>}
                                    </Box>
                                    <Button
                                        component={RouterLink}
                                        variant="contained"
                                        color="primary"
                                        classes={{root: `${classes.button} ${classes.headerButton}`}}
                                        to={`/${PAGES_SLUGS.OFFERS}/${_get(data, 'slug', '')}`}
                                    >
                                        Cere oferta
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                    </Container>
                </div>

            </HeaderOfferObserver>
        </React.Fragment>
    )
}

const RenderVariationButtons = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const {variations} = props;
    const {selectedVariationID} = props;

    return (
        <div className={classes.chipParent}>
            {variations.map((variation, index) => {
                if (_get(variation, "external_cab_variant_id", '') === selectedVariationID)
                    return <span key={index} className={clsx(classes.chip, classes.currentChip)}>
                        {_get(variation, "variant_name", '')}
                    </span>

                return (
                    <Link key={index}
                          className={classes.chip}
                          to={{
                              pathname: `/${PAGES_SLUGS.ACCESSORY_DETAIL}/${variation.slug}`, state: {from: location.pathname}}}
                    >
                        {_get(variation, "variant_name", '')}
                    </Link>
                )
            })}
        </div>
    )
}

const offerHeaderTransition = 0.2;

const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    ...itemDetailsStyles(theme),
    lightGrey: {
        background: "#eee",
    },
    slide: {
        position: "relative",
        paddingTop: "56.28%",
        width: "100%",
        background: "#eee",
    },
    image: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        objectFit: "contain"
    },
    thumbSlide: {
        width: "100%",
        paddingTop: "56.17%",
        position: "relative",
        cursor: "pointer",
    },
    thumbImage: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        objectFit: "contain",
        opacity: 0.4,
        transition: theme.transitions.create(['opacity'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        ".swiper-slide-thumb-active &": {
            opacity: 1,
        },
    },
    header: {
        position: "relative",
    },
    headerContent: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(0),
    },
    button: {
        backgroundColor: "#284E80",
        marginRight: theme.spacing(3)
    },
    buttonMargin: {
        marginBottom: theme.spacing(3),
    },
    title: {
        letterSpacing: 0,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(36),
        lineHeight: theme.typography.pxToRem(48),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(24),
            lineHeight: theme.typography.pxToRem(32),
        },
    },
    sku: {
        color: '#707070',
        fontSize: '14px',
        fontWeight: '300',
        letterSpacing: 0,
        lineHeight: '24px',
        paddingBottom: theme.spacing(2),
    },
    text: {
        letterSpacing: 0,
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(24),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(16),
        },

    },
    headerRoot: {
        background: `#fafafa`,
    },
    headerSizing: {
        height: 100,
    },
    offerHeader: {
        zIndex: 1101,
        top: 0,
        opacity: 0,
        visibility: `hidden`,
        width: `100%`,
        position: `fixed`,
        transition: `opacity ${offerHeaderTransition}s 0s, visibility 0s ${offerHeaderTransition}s`
    },
    showHeader: {
        opacity: 1,
        visibility: `visible`,
        transition: `opacity ${offerHeaderTransition}s, visibility ${offerHeaderTransition}s 0s`
    },
    headerTitle: {
        fontSize: `1.125rem`,
        fontWeight: 500,
        lineHeight: `1.5rem`,
        overflow: `hidden`,
        textOverflow: `ellipsis`,
        display: `-webkit-box`,
        lineClamp: 3,
        boxOrient: `vertical`,
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(0),
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(20),
        },
    },
    headerPrice: {
        textAlign: `center`,
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            textAlign: "left",
            marginRight: theme.spacing(0),
            fontSize: theme.typography.pxToRem(12),
            lineHeight: theme.typography.pxToRem(20),

        },
    },
    headerOldPrice: {
        marginRight: theme.spacing(5),
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(2),
            fontSize: theme.typography.pxToRem(12),
            lineHeight: theme.typography.pxToRem(20),
        },
    },
    headerButtonText: {},
    headerButton: {
        textAlign: `center`,
        fontSize: theme.typography.pxToRem(14),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(12),
            lineHeight: theme.typography.pxToRem(20),
            padding: theme.spacing(1)
        },
    },
    mobileHeaderWrapper: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: `column`,
            justifyContent: `space-evenly`,
            alignItems: `flex-start`,
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        }
    },
    mobileTextWrapper: {

        [theme.breakpoints.down('sm')]: {
            alignItems: `center`,
            alignSelf: `stretch`,
            justifyContent: `space-between`,
        }
    },
    mobilePricesWrapper: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: `column`
        }
    },

    // Accessories
    shortDescription: {
        fontSize: `1.12rem`,
        lineHeight: 1.5
    },
    chipParent: {
        marginBottom: theme.spacing(2),
    },
    currentChip: {/*Empty class for child selector*/},
    chip: {
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.light,
        backgroundColor: theme.palette.primary.contrastText,
        display: `inline-block`,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(24),
        border: `1px solid`,
        borderRadius: theme.spacing(2),
        textDecoration: `none`,
        fontWeight: theme.typography.fontWeightMedium,

        '&$currentChip': {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        },
    }
}));

export default AccessoryDetailsSlider
