import React from "react";
import {withStyles} from "@material-ui/core/styles";

const customStyles = {
    bullet: {
        display: "inline-block",
        width: "1rem",
        height: "1rem",
        borderRadius: "50%",
        marginRight: ".25rem",
        border: "1px solid #b4b4b4",
        verticalAlign: "text-bottom"
    },
};

const ColorBullet = ({ classes, color }) => (
    <span className={classes.bullet} style={{ backgroundColor: color }} />
);
export default withStyles(customStyles)(ColorBullet);
