import React from 'react';
import _get from "lodash/get";
import {Link, useLocation} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";
import {PAGES_SLUGS} from "../constants";
import clsx from "clsx";
import Skeleton from "@material-ui/lab/Skeleton";
import {getWishlistData, WL} from "../helpers/localStorage";
import listingCardGeneralStyles from "../theme/listingCardGeneralStyles";
import toLocale from "../helpers/toLocale";
import {appStyle} from "../theme/appStyle";

const ProductCardBlueWeeks = (props) => {
    const classes = useStyles();
    const location = useLocation();

    return <ButtonBase
        focusRipple
        component={Link}
        to={{pathname: `/${PAGES_SLUGS.DETAILS}/${props.slug}`, state: {from: location.pathname}}}
        className={classes.root}
    >
        <Box position='relative' className={classes.imageWrapper}>
            <Box display={"flex"} className={`${classes.absoluteChips}`}>
                {_get(props, "discount", false) && <div>
                    <div className={`${classes.discountChip}`}>
                        -{toLocale(props.discount)}%
                    </div>
                </div>}
            </Box>

            <img data-src={props.image} className={clsx("swiper-lazy", classes.image)}/>
            {getWishlistData(WL.CAR).includes(props.slug) &&
            <div className={classes.wishlistHeart}>
                <Icon className={`${classes.wlIcon} brand-icon-heart-fill`}/>
            </div>}
            <Skeleton variant={'rect'} width="100%" height={"100%"}
                      className={"preloader-skeleton"}/>
            {_get(props, "sku", false) && <Typography className={classes.sku}># {props.sku}</Typography>}
        </Box>
        <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} height={"100%"}
             py={2} px={3}>
            <div>
                <Typography paragraph className={classes.title}>{ReactHtmlParser(props.title)}</Typography>
            </div>
            <Box display={"flex"}
                 alignItems={"center"}
                 justifyContent={"space-between"}
            >
                <Typography component={'div'} className={classes.text}>
                    <span className={classes.redText}>
                        {props.price} + TVA
                    </span>
                    {props.oldPrice &&
                        <div className={`${classes.oldPrice} ${classes.colorBlueGrey}`}>
                            <span className={classes.oldPriceLineThrough}>{props.oldPrice}</span>
                            {props.discount &&
                                <span className={classes.oldPriceDiscount}>&nbsp;+ TVA </span>
                            }
                        </div>
                    }
                </Typography>
                <Typography variant={'caption'} className={`${classes.weight300}`}>
                    <span className={clsx("brand-icon-right-nav-arrow", classes.redText)}> </span>
                </Typography>
            </Box>
        </Box>
    </ButtonBase>
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        background: "#fff",
        borderRadius: "8px",
        overflow: "hidden",
    },
    imageWrapper: {
        width: "100%",
        paddingTop: "56%",
        position: "relative",
    },
    image: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        transform: "scale(1.01)",
        transition: "transform 300ms",
        "&:hover": {
            transform: "scale(1.08)",
        }
    },
    discountChip: {
        ...appStyle(theme).chipBase,
        background: `#9E2B2B`,
        fontWeight: 500,
    },
    absoluteChips: {
        position: `absolute`,
        zIndex: 1,
        pointerEvents: `none`,
        top: theme.spacing(1)
    },
    sku: {
        position: "absolute",
        bottom: theme.spacing(1),
        left: theme.spacing(1),
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(12),
        color: "#fff",
    },
    title: {
        fontSize: 16,
        fontWeight: "500",
        letterSpacing: "0px",
        lineHeight: "24px",
    },
    text: {
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: 0,
        fontWeight: 300,
    },
    redText: {
        fontWeight: 500,
        color: `#9E2B2B`,
    },
    oldPrice: {
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: 0,
        fontWeight: 300,
        color: `#C0C9D6`
    },
    oldPriceLineThrough: {
        textDecoration: "line-through",
    },
    link: {
        fontSize: "14px",
        lineHeight: "1",
        padding: `${theme.spacing(1) / 2}px`,
        position: "relative",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "none",
        },
        "&:before": {
            content: `''`,
            position: "absolute",
            width: "0",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            background: theme.palette.primary.main,
            transition: theme.transitions.create(['width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        "&:hover:before": {
            content: `''`,
            position: "absolute",
            width: "100%",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            background: theme.palette.primary.main
        }
    },
    icon: {
        fontSize: 10,
    },
    wishlistHeart: {
        ...listingCardGeneralStyles(theme).wishlistHeart
    },
    wlIcon: {
        ...listingCardGeneralStyles(theme).wlIcon
    }
}));
export default ProductCardBlueWeeks;
