import React, {Component} from 'react';
import {connectHierarchicalMenu} from "react-instantsearch-dom";
// UI
import {withStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import filterStyles from "../../theme/filterStyles";
import Fab from '@material-ui/core/Fab';
import AlgoliaClearRefinements from "../ContentBlocks/Cars/AlgoliaClearRefinements";
import extractFilterName from "../../helpers/extractFilterName";
import {accCategoryDictionary, dictionary} from "../../constants/algolia";
import clsx from "clsx";


class AlgoliaHierarchicalMenu extends Component {
    state = {
        expanded: this.props.expanded
    }

    accordionChange = (event, expanded) => {
        this.setState({expanded: expanded})
    }

    renderNestedLists = (classes, items, refine) => {
        return (
            items.map((item, index) => (
                <React.Fragment key={`${index}${item.label}`}>
                    <FormControlLabel
                        className={classes.formLabel}
                        classes={{label: classes.customLabel}}
                        control={
                            <Checkbox
                                checkedIcon={<div className={classes.customCheckbox}> </div>}
                                icon={<div className={classes.emptyCustomCheckbox}> </div>}
                                color={'primary'}
                                className={classes.smallerPadding}
                                checked={item.isRefined}
                                onClick={event => {
                                    event.preventDefault();
                                    refine(item.value);
                                }}
                                value={item.label}/>
                        }
                        label={accCategoryDictionary[extractFilterName(item.label)]}
                    />
                    {item.items && (
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                                {this.renderNestedLists(classes, item.items, refine)}
                            </FormGroup>
                        </FormControl>
                    )}
                </React.Fragment>
            ))
        )
    }


    render() {
        const {items, currentRefinement, refine, classes, expanded} = this.props;
        const parentAttribute = this.props.attribute
        const filterName = Boolean(dictionary[extractFilterName(parentAttribute)])
            ? dictionary[extractFilterName(parentAttribute)]
            : extractFilterName(parentAttribute);
        // console.log(this.props);
        return (
            <div className={clsx(classes.root)}>
                <div className={classes.filterBox}>
                    <Accordion defaultExpanded={expanded} classes={{root: classes.expansionRoot}}
                               onChange={this.accordionChange}
                    >
                        <AccordionSummary
                            classes={{
                                root: classes.expansionButton,
                                content: classes.expansionButtonContent,
                                expandIcon: classes.icon
                            }}
                            expandIcon={<span className={`brand-icon-down-arrow`}/>}>
                            <Typography variant={"body1"} className={`${classes.categoryTitle} ${currentRefinement && classes.selectedFilters}`}>{filterName}</Typography>
                        </AccordionSummary>
                        {/*<div className={classes.tags}>*/}
                        <AccordionDetails className={classes.details} classes={{root: classes.expansion}}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                    {this.renderNestedLists(classes, items, refine)}
                                    <AlgoliaClearRefinements
                                        transformItems={items =>
                                            items.filter(({ attribute }) => (attribute === parentAttribute || attribute === 'hierarchicalCategories.lvl0'))
                                        }
                                    />
                                </FormGroup>
                            </FormControl>
                        </AccordionDetails>

                    </Accordion>
                </div>
                {!this.state.expanded && <div>
                    {items.map((item, index) => {
                        if (item.isRefined)
                            return <Fab
                                variant={"extended"}
                                className={classes.tag}
                                classes={{
                                    root:classes.tabLabel
                                }}
                                key={index}
                                size="small"
                                color="primary"
                                onClick={event => {
                                    event.preventDefault();
                                    refine(item.value);
                                }}>
                                {item.label}<span className={'brand-icon-close'}> </span>
                            </Fab>
                    })}
                </div>
                }
            </div>
        );
    }
}

AlgoliaHierarchicalMenu = connectHierarchicalMenu(AlgoliaHierarchicalMenu);
export default withStyles(filterStyles)(AlgoliaHierarchicalMenu);
