import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import _uniqueId from "lodash/uniqueId"
import _map from "lodash/map";
import _get from "lodash/get";
import toLocale from "../../helpers/toLocale";
import ProductCardBlueWeeks from "../ProductCardBlueWeeks";
import {SwiperSlide} from 'swiper/react';
import Slider from "./Slider";
/*demo image*/
import clsx from "clsx";
import getSuppotedFileType from "../../helpers/getSuppotedFileType";
import {sliderStyle} from "../../theme/appStyle";

const BlueWeeksSlider = (props) => {
    const classes = useStyles();
    const paginationEl = _uniqueId("last-mile-pagination-");

    return (
        <div className={classes.sliderRoot}>
            <Slider
                spaceBetween={16}
                slidesPerView={1}
                slidesPerGroup={1}
                pagination={{
                    el: `.${paginationEl}`,
                    dynamicBullets: false,
                    clickable: true
                }}
                preloadImages={false}
                lazy={{
                    preloaderClass: "preloader-skeleton"
                }}
                breakpoints={{
                    420: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 16
                    },
                    640: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        spaceBetween: 16,
                        centeredSlides:false
                    },
                    1024: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 24
                    },
                    1600: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 24
                    }
                }}
            >
                {_map(props.data, (item, index) => {
                    return <SwiperSlide key={index}>
                        <ProductCardBlueWeeks
                            sku={_get(item, "car.rom_ref", [])}
                            // image={_get(item, "thumb", '')}
                            image={getSuppotedFileType(_get(item, "car.photos[0].thumb", []))}
                            title={_get(item, "car.short_name", "")}
                            price={`${toLocale(_get(item, "car.client_invoice_price", ""))}€`}
                            oldPrice={`${toLocale(_get(item, "car.total_car_price", ""))}€`}
                            discount={`${toLocale(_get(item, "car.discount_percentage", ""))}`}
                            slug={_get(item, "car.slug", "")}
                        />

                    </SwiperSlide>
                })}
            </Slider>
            <div className={clsx(paginationEl, classes.paginationContainer, classes.bluePagination)}>
            </div>
        </div>

    );
};

const useStyles = makeStyles((theme) => ({
    ...sliderStyle(theme),
    sliderRoot: {
        ...sliderStyle(theme).sliderRoot,
        "& .swiper-slide": {
            height: `auto`
        },
    },
    bluePagination: {
        [theme.breakpoints.down('sm')]: {
            padding: "48px 0",
        },
    }
}))

export default BlueWeeksSlider
