import React from 'react';
import {Link, useLocation} from 'react-router-dom';
/*ui*/
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
/*component*/
import Typography from "@material-ui/core/Typography";
import {appStyle} from "../theme/appStyle";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import {getWishlistData, WL} from "../helpers/localStorage";
import _get from "lodash/get";
import LazyImage from "./LazyImage";
import getSuppotedFileType from "../helpers/getSuppotedFileType";
import {PAGES_SLUGS} from "../constants";
import listingCardGeneralStyles from "../theme/listingCardGeneralStyles";
import DetailsCarPrice from "./ContentBlocks/Cars/DetailsCarPrice";

const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    ...listingCardGeneralStyles(theme),
    chipEcobonus: {
        ...appStyle(theme).chipBase,
        background:"#52A30A",
    },
    chipReserved: {
        ...appStyle(theme).chipBase,
        background:"#284E80",
    },
    technicalDataDescription: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function DetailedCarCard(props) {
    const classes = useStyles();
    const location = useLocation();
    const carData = props.data;

    return (
        <Card elevation={0} className={classes.carCard}>
            <Link to={{pathname: `/${PAGES_SLUGS.DETAILS}/${carData.slug}`, state: {from: location.pathname}}} className={classes.linkDecoration}>
                <div className={`${classes.imageContainer}`}>
                    <Box position='relative'>
                        <LazyImage src={getSuppotedFileType(_get(carData, "media[0].thumb", []))} ratio={56}
                                   isAnimated={true} alt={carData.name}/>
                        {getWishlistData(WL.CAR).includes(carData.slug) &&
                        <div className={classes.wishlistHeart}>
                            <Icon className={`${classes.wlIcon} brand-icon-heart-fill`}/>
                        </div>}
                    </Box>

                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} className={classes.chipRow}>
                        {_get(carData, "rom_ref", false) &&
                        <Typography component={"div"} className={classes.sku}># {carData.rom_ref}</Typography>}

                        <Box display={"flex"}>
                            {_get(carData, "rezervat", false) && <div>
                                <div className={classes.chipReserved}>REZERVAT</div>
                            </div>}
                            {(_get(carData, "ecobonus", false) || _get(carData, "ecobonus_bev", false)) && <div>
                                <div className={classes.chipEcobonus}>ECO BONUS</div>
                            </div>}
                        </Box>

                    </Box>
                </div>
                {/*<Typography variant="caption" className={classes.idCaption}># {carData.rom_ref}</Typography>*/}
                <Box display={"flex"} flexDirection={"column"} flexGrow={1} className={classes.detailsContainer}>
                    <Typography
                        className={`${classes.carName} ${classes.weight500}`}>{_get(carData, "short_name", "")}</Typography>
                    <Grid container spacing={1} className={classes.technicalDataDescription}>
                        <Grid item xs={4} container
                              direction="column"
                              alignItems="center">
                            <span className={"brand-icon-medium brand-icon-fuel"}> </span>
                            <Typography variant={"caption"} align={"center"}>{carData.fuel}</Typography>
                        </Grid>
                        <Grid item xs={4} container
                              direction="column"
                              alignItems="center">
                            <span className={"brand-icon-medium brand-icon-transmission"}> </span>
                            <Typography variant={"caption"} align={"center"}>{carData.transmission_type}</Typography>
                        </Grid>
                        <Grid item xs={4} container
                              direction="column"
                              alignItems="center">
                            <span className={"brand-icon-medium brand-icon-traction"}> </span>
                            <Typography variant={"caption"} align={"center"}>{carData.transmission}</Typography>
                        </Grid>

                    </Grid>
                    <Divider/>
                    <Box display={"flex"}
                         alignItems={"center"}
                         justifyContent={"space-between"}
                         pt={1}
                    >
                        <Typography variant={'h5'} color={"primary"} className={classes.weight300}>
                            {DetailsCarPrice(carData)}
                        </Typography>
                        <Typography variant={'caption'} color={"primary"} className={classes.weight300}>
                            <span className={"brand-icon-right-nav-arrow"}> </span>
                        </Typography>
                    </Box>
                </Box>
            </Link>
        </Card>
    );
}

export default DetailedCarCard;
