import React from 'react';
import {Link, useLocation} from 'react-router-dom';
/*ui*/
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
/*component*/
import Typography from "@material-ui/core/Typography";
import {appStyle} from "../theme/appStyle";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import {getWishlistData, WL} from "../helpers/localStorage";
import _get from "lodash/get";
import LazyImage from "./LazyImage";
import getSuppotedFileType from "../helpers/getSuppotedFileType";
import {PAGES_SLUGS} from "../constants";
import listingCardGeneralStyles from "../theme/listingCardGeneralStyles";
import DetailsCarPriceBlue from "./ContentBlocks/Cars/DetailsCarPriceBlue";
import toLocale from "../helpers/toLocale";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    ...listingCardGeneralStyles(theme),
    chipEcobonus: {
        ...appStyle(theme).chipBase,
        background:"#52A30A",
    },
    blueChip: {
        ...appStyle(theme).chipBase,
        background: theme.palette.primary.dark,
    },
    discountChip: {
        ...appStyle(theme).chipBase,
        background: `#9E2B2B`,
    },
    absoluteChips: {
        position: `absolute`,
        zIndex: 1,
        pointerEvents: `none`,
        top: theme.spacing(1)
    },
    chipReserved: {
        color: "#FFFFFF",
        fontSize: theme.typography.pxToRem(10),
        letterSpacing: 0,
        lineHeight: theme.typography.pxToRem(24),
        fontWeight: theme.typography.fontWeightLight,
        borderRadius:"4px",
        background:"#284E80",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        display:"inline-block"
    },
    technicalDataDescription: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function DetailedCarCardBlue(props) {
    const classes = useStyles();
    const location = useLocation();
    const carData = props.data;

    return (
        <Card elevation={0} className={classes.carCard}>
            <Link to={{pathname: `/${PAGES_SLUGS.DETAILS}/${carData.slug}`, state: {from: location.pathname}}} className={classes.linkDecoration}>
                <div className={`${classes.imageContainer}`}>
                    <Box position='relative'>
                        <Box display={"flex"} className={`${classes.absoluteChips}`}>
                            {(_get(carData, "discount_percentage", 0) !== 0) && <div>
                                <div className={`${classes.discountChip}`}>
                                    -{toLocale(carData.discount_percentage)}%
                                </div>
                            </div>}
                            <div className={`${classes.blueChip}`}>Blue Weeks</div>
                        </Box>


                        <LazyImage src={getSuppotedFileType(_get(carData, "media[0].thumb", []))} ratio={56}
                                   isAnimated={true} alt={carData.name}/>
                        {getWishlistData(WL.CAR).includes(carData.slug) &&
                        <div className={classes.wishlistHeart}>
                            <Icon className={`${classes.wlIcon} brand-icon-heart-fill`}/>
                        </div>}
                    </Box>

                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} className={classes.chipRow}>
                        {_get(carData, "rom_ref", false) &&
                        <Typography component={"div"} className={classes.sku}># {carData.rom_ref}</Typography>}

                        <Box display={"flex"}>
                            {_get(carData, "rezervat", false) && <div>
                                <div className={classes.chipReserved}>REZERVAT</div>
                            </div>}
                            {(_get(carData, "ecobonus", false) || _get(carData, "ecobonus_bev", false)) && <div>
                                <div className={classes.chipEcobonus}>ECO BONUS</div>
                            </div>}
                        </Box>

                    </Box>
                </div>
                {/*<Typography variant="caption" className={classes.idCaption}># {carData.rom_ref}</Typography>*/}
                <Box display={"flex"} flexDirection={"column"} flexGrow={1}>
                    <Box display={"flex"} flexDirection={"column"} flexGrow={1} px={3}>
                        <Typography
                            className={`${classes.carName} ${classes.weight500}`}>{_get(carData, "short_name", "")}</Typography>
                        <Grid container spacing={1} className={classes.technicalDataDescription}>
                            <Grid item xs={4} container
                                  direction="column"
                                  alignItems="center">
                                <span className={"brand-icon-medium brand-icon-fuel"}> </span>
                                <Typography variant={"caption"} align={"center"}>{carData.fuel}</Typography>
                            </Grid>
                            <Grid item xs={4} container
                                  direction="column"
                                  alignItems="center">
                                <span className={"brand-icon-medium brand-icon-transmission"}> </span>
                                <Typography variant={"caption"} align={"center"}>{carData.transmission_type}</Typography>
                            </Grid>
                            <Grid item xs={4} container
                                  direction="column"
                                  alignItems="center">
                                <span className={"brand-icon-medium brand-icon-traction"}> </span>
                                <Typography variant={"caption"} align={"center"}>{carData.transmission}</Typography>
                            </Grid>

                        </Grid>
                        <Divider/>
                    </Box>
                    <Box pt={1}
                         bgcolor="primary.dark"
                    >
                        <Box display={"flex"}
                             alignItems={"center"}
                             justifyContent={"space-between"}
                             pb={1.25}
                             px={3}
                        >
                            <Typography variant={'h5'} className={`${classes.weight300} ${classes.whiteText} ${classes.weight500}`}>
                                {DetailsCarPriceBlue(carData, false)}
                            </Typography>
                            <Typography variant={'caption'} className={`${classes.weight300}`}>
                                <span className={clsx("brand-icon-right-nav-arrow", classes.whiteText)}> </span>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Link>
        </Card>
    );
}

export default DetailedCarCardBlue;
