import * as ActionTypes from './constants';
import {indexName, searchClient, staticOrder} from "../../constants/algolia";
import extractFilterName from "../../helpers/extractFilterName";

export function fetchFacets() {
    return function (dispatch) {
        const index = searchClient.initIndex(indexName);
        index.search('', {
            facets: ['*']
        }).then((response) => {
            const facets = Object.keys(response.facets);
            // Use Array.prototype.sort to order the facets via the static order
            const orderedFacets = facets.sort((a, b) => {
                const indexA = staticOrder.indexOf(extractFilterName(a));
                const indexB = staticOrder.indexOf(extractFilterName(b));
                // If both items are in the static array, sort by their index in the static array
                if (indexA !== -1 && indexB !== -1) {
                    return indexA - indexB;
                }
                // If only item A is in the static array, it should come before item B
                if (indexA !== -1) return -1;
                // If only item B is in the static array, it should come before item A
                if (indexB !== -1) return 1;
                // If neither item is in the static array, maintain their original order
                return 0;
            });
            dispatch({
                type: ActionTypes.FACETS_SUCCESS,
                payload: orderedFacets.filter(Boolean)
            });

        }).catch((response) => {
            const {errors, message} = response.data;
            console.log(errors);
            console.log(message);
            dispatch({
                type: ActionTypes.FACETS_ERROR,
                payload: errors
            });
        });
    }
}
