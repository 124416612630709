import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import Fade from "react-reveal/Fade";
import clsx from "clsx";
import Skeleton from "@material-ui/lab/Skeleton";
import CountdownBlueWeeks from "./BlueWeeks/Countdown";
import Hidden from "@material-ui/core/Hidden";
import {PAGES_SLUGS} from "../constants";
import bannerStatic from "../../images/hp-banner-blue-weeks.png";

const BannerCardBlueWeeks = () => {
    const classes = useStyles();

    return <ButtonBase disableRipple={true} className={classes.slide} component={RouterLink} to={`/${PAGES_SLUGS.BLUE_WEEK}`}>
        <Box display={"flex"}
             flexDirection={"column"}
             alignItems={"center"}
             justifyContent={"center"}
             bgcolor="primary.dark"
             className={classes.countdownTimerBlock}
        >
            <Box display={"flex"} flexDirection={"column"} className={classes.timerParent}>
                <Fade delay={1000}>
                    <div className={classes.primaryText}>A început Volvo <Hidden mdUp><br/></Hidden> Blue Weeks</div>
                    <div className={classes.secondaryText}>
                        Profită de oferte exclusive la întreaga gamă de modele Volvo și bucură-te de avantaje speciale.
                    </div>
                    <CountdownBlueWeeks/>
                </Fade>
            </Box>
        </Box>

        <img data-src={bannerStatic}
             className={clsx("swiper-lazy", classes.image)}/>
        <Skeleton variant={'rect'} width="100%" height={"100%"}
                  className={"preloader-skeleton"}/>
    </ButtonBase>
}

const useStyles = makeStyles((theme) => ({
    slide: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column-reverse"
        },
    },
    countdownTimerBlock: {
        width: "100%",
        [theme.breakpoints.up('md')]: {
            width: "53%",
        },
        [theme.breakpoints.up('lg')]: {
            width: "40%",
        },
    },
    image: {
        objectFit: "cover",
        width: "100%",
        [theme.breakpoints.up('md')]: {
            width: "47%",
            height: 600,
        },
        [theme.breakpoints.up('lg')]: {
            width: "60%"
        },
        [theme.breakpoints.down('sm')]: {
            height: 280,
        },
    },
    timerParent: {
        gap: `1rem`,
        padding: `1rem 1rem 2rem`,
        [theme.breakpoints.up('md')]: {
            padding: 0,
            width: "75%",
            gap: `2rem`,
        },
    },
    slideText: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        "& $container": {
            height: "calc(100% - 160px)",
        },
        [theme.breakpoints.down('sm')]: {
            background: "#141414",
            position: "static",
            bottom: "auto",
            left: "auto",
            right: "auto",
            top: "auto",
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(4),
        }
    },

    container: {
        [theme.breakpoints.between('lg', 'xl')]: {
            maxWidth: "1440px",
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },

    slideTextPosition: {
        height: "100%",
        maxWidth: "45%",
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
        }
    },

    primaryText: {
        color: "#FFFFFF",
        fontSize: theme.typography.pxToRem(38),
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: theme.typography.pxToRem(42),
        letterSpacing: "0.95px",
        [theme.breakpoints.down('sm')]: {
            fontFamily: "inherit",
            fontSize: theme.typography.pxToRem(28),
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: theme.typography.pxToRem(28),
        }
    },
    secondaryText: {
        color: "#FFFFFF",
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightRegular,
        letterSpacing: "0.4px",
        lineHeight: theme.typography.pxToRem(24),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(15),
            lineHeight: theme.typography.pxToRem(16),
            fontWeight: theme.typography.fontWeightRegular,
        }
    },
}));
export default BannerCardBlueWeeks;
