const appStyle = theme => {
    return {
        absolute: {
            position: 'absolute',
        },
        relative: {
            position: 'relative',
        },
        fixed: {
            position: 'fixed',
        },
        flex: {
            flex: 1,
        },
        flexGrow: {
            flexGrow: 1,
        },
        uppercase: {
            textTransform: 'uppercase'
        },
        capitalize: {
            textTransform: "capitalize"
        },
        textCenter: {
            textAlign: "center",
        },
        textLeft: {
            textAlign: "left",
        },
        textRight: {
            textAlign: "right",
        },
        noPadding: {
            padding: `0 !important`
        },
        whiteText: {
            color: `#fff`
        },
        colorPrimary: {
            color: theme.palette.primary.main,
        },
        colorDark: {
            color: `#000`
        },
        colorDarkGrey: {
            color: `rgba(0, 0, 0, 0.87)`
        },
        colorLightGrey: {
            color: `rgba(0, 0, 0, 0.54)`
        },
        colorBlueGrey: {
            color: `#C0C9D6`
        },
        fontSize24: {
            fontSize: 24
        },
        fontSize16: {
            fontSize: 16
        },
        fontSize14: {
            fontSize: 14
        },
        fontSize12: {
            fontSize: 12
        },
        weight300: {
            fontWeight: 300
        },
        weight400: {
            fontWeight: 400
        },
        weight500: {
            fontWeight: 500
        },
        weight900: {
            fontWeight: 900
        },
        hidden: {
            display: `none`
        },
        pointer: {
            cursor: `pointer`
        },
        rounded: {
            borderRadius: `50%`
        },
        emptyCustomCheckbox: {
            height: 20,
            width: 20,
            border: `1px solid #CCCCCC`,
        },
        customCheckbox: {
            height: 20,
            width: 20,
            border: `1px solid #CCCCCC`,
            '&:before': {
                content: `'✓'`,
                lineHeight: 1,
                fontSize: 17,
                display: `block`
            }
        },
        dialogActions: {
            borderTop: `1px solid rgba(0, 0, 0, 0.08)`,
            background: "#fff",
            position: "fixed",
            left: '0px',
            width: "100%",
            bottom: 0,
            padding: 0
        },
        hiddenSmDown: {
            [theme.breakpoints.down('sm')]: {
                display: `none !important`
            },
        },
        hiddenLgDown: {
            [theme.breakpoints.down('lg')]: {
                display: `none !important`
            },
        },
        hiddenMdUp: {
            [theme.breakpoints.up('md')]: {
                display: `none !important`
            },
        },
        hiddenXlUp: {
            [theme.breakpoints.up('xl')]: {
                display: `none !important`
            },
        },
        underlineLink: {
            fontSize: theme.typography.pxToRem(14),
            lineHeight: 1,
            padding: theme.spacing(1/2),
            position: "relative",
            cursor: "pointer",
            "&:hover": {
                textDecoration: "none",
            },
            "&:before": {
                content: `''`,
                position: "absolute",
                width: "0",
                height: "1px",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                background: theme.palette.primary.main,
                transition: theme.transitions.create(['width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            "&:hover:before": {
                width: "100%"
            }
        },
        underlineLinkButton: {
            fontWeight: theme.typography.fontWeightLight,
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(24),
            color: theme.palette.secondary.main,
            position: 'relative',
            textDecoration: "none",
            "&:hover": {
                textDecoration: "none",
            },
            [theme.breakpoints.up('md')]: {
                "&:before": {
                    content: `''`,
                    position: "absolute",
                    width: "0",
                    height: "1px",
                    bottom: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    background: theme.palette.primary.main,
                    transition: theme.transitions.create(['width'], {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                },
                "&:hover:before": {
                    width: "100%",
                },
            },
            [theme.breakpoints.down('sm')]: {
                width: "100%",
            },

            '& $icon': {
                color: theme.palette.primary.main,
                fontSize: "20px",
                marginRight: theme.spacing(2),
            }
        },
        icon: {/*Empty class for child selector*/},
        buttonItem: {
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
                width: "100%",
            },
        },
        sectionIcon: {
            fontSize: "40px",
            marginRight: theme.spacing(3),
        },
        pageTitle: {
            margin: theme.spacing(1, 'auto', 3),
            letterSpacing: .4
        },
        pageSubtitle: {
            marginBottom: theme.spacing(5),
            letterSpacing: .64,
            textTransform: `uppercase`
        },
        listingContainer: {
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0, 3),
            },
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: theme.spacing(5),
                paddingRight: theme.spacing(5),
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: theme.spacing(7),
                paddingRight: theme.spacing(7),
            },
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        chipBase: {
            color: "#FFFFFF",
            fontSize: theme.typography.pxToRem(10),
            lineHeight: theme.typography.pxToRem(24),
            fontWeight: theme.typography.fontWeightLight,
            textTransform: "uppercase",
            borderRadius:"4px",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            display:"inline-block"
        },
    }
};

const sliderStyle = theme => {
    return {
        sliderRoot: {
            "& .swiper-pagination": {
                lineHeight: 1,
                fontSize: 0,
                position: "static",
            },
            "& .swiper-pagination-bullet ": {
                height: 4,
                width: 88,
                borderRadius: 2,
                display: "inline-block",
                verticalAlign: "middle",
                background: "#ECECEC",
                opacity: 1,
                // Looks like there's a bug and the swiper-pagination-clickable class isn't applied even if set
                // So we manually add cursor pointer to all bullets
                cursor: 'pointer',
                margin: "0 12px",
                [theme.breakpoints.down('sm')]: {
                    width: 30,
                    margin: "0 6px",
                },
            },
            "& .swiper-pagination-bullet-active": {

                background: theme.palette.primary.main,
                outline: "none",
                "&:hover:focus": {
                    outline: "none",
                },
                "&:hover:active": {
                    outline: "none",
                }
            },
            "& .swiper-pagination-bullet-active:hover:focus": {
                outline: "none",
            },
            "& .swiper-pagination-bullets": {
                outline: "none",
                "&:hover:focus": {
                    outline: "none",
                },
                "&:hover:active": {
                    outline: "none",
                }
            },
            "&  .swiper-pagination-bullet:hover:focus": {
                outline: "none",
            },
        },
        paginationContainer: {
            textAlign: "center",
            padding: "60px 0",
            [theme.breakpoints.down('sm')]: {
                padding: "0px 0 32px",
            },
        }
    }
};

export {appStyle, sliderStyle};
