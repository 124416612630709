import React, {useState} from "react";
import {Collapse} from "react-collapse";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _slice from "lodash/slice";
import clsx from "clsx";

import Link from "@material-ui/core/Link";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

import EquipmentDataCard from "../EquipmentDataCard";

const StandardEqSectionWidget = (props) => {
    const classes = useStyles();
    const [isOpened, setIsOpened] = useState(false);
    const {data} = props;
    // console.log(data);

    const handleToggle = (event) => {
        event.preventDefault();
        setIsOpened(!isOpened);
    }

    const renderToggleText = () => {
        if (isOpened) {
            return <Link href={"#"} onClick={handleToggle} className={classes.link}>
                <span className={classes.cta}>Mai putin</span>
                <Icon className={clsx("brand-icon-up-arrow", classes.icon)}/>
            </Link>
        } else {
            return <Link href={"#"} onClick={handleToggle} className={classes.link}>
                <span className={classes.cta}>Mai mult</span>
                <Icon className={clsx("brand-icon-down-arrow", classes.icon)}/>
            </Link>
        }
    }

    const renderSampleData = () => {
        if (!_isEmpty(data)) {
            return _map(_slice([...data], 0, 2), (item, index) => {
                return <EquipmentDataCard
                    key={index}
                    name={item.name}
                    data={item.data}
                    accessoryPage={props.accessoryPage || false}
                />
            })
        }
    }

    const renderCollapsedData = () => {
        if (!_isEmpty(data)) {
            if (data.length > 2) {
                return <>
                    <Collapse isOpened={isOpened} theme={{collapse: classes.collapse, content: classes.content}}>
                        {_map(_slice([...data], 2, data.length), (item, index) => {
                            return <EquipmentDataCard
                                key={index}
                                name={item.name}
                                data={item.data}
                                accessoryPage={props.accessoryPage || false}
                            />
                        })}
                    </Collapse>
                    <Grid item xs={12} className={classes.sectionBox}>
                        {renderToggleText()}
                    </Grid>
                </>
            }
        }
    }

    return <div>
        {renderSampleData()}
        {renderCollapsedData()}
    </div>
}

const useStyles = makeStyles((theme) => ({
    link: {
        display: "inline-flex",
        alignItems: "center",
        position: 'relative',
        textDecoration: "none",
        "&:hover": {
            textDecoration: "none",
        },
        "&:before": {
            content: `''`,
            position: "absolute",
            width: "0",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            background: theme.palette.primary.main,
            transition: theme.transitions.create(['width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        "&:hover:before": {
            content: `''`,
            position: "absolute",
            width: "100%",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            background: theme.palette.primary.main
        }
    },
    content: {
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
    },
    collapse: {
        width: "100%",
        transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    cta: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(24),
    },
    icon: {
        fontSize: "14px",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
        color: theme.palette.primary.main,
    }
}))

export default StandardEqSectionWidget;
