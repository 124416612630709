import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
// import Swiper core and required components
import SwiperCore, {Autoplay, A11y, Navigation, Pagination, Scrollbar} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
/*demo image*/
import BannerCard from "../BannerCard";
import BannerCardBlueWeeks from "../BannerCardBlueWeeks";
import _map from "lodash/map";
import _get from "lodash/get";


// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default (props) => {
    const classes = useStyles();
    // console.log("BannerSlide",props);
    return (
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            // navigation
            pagination={{
                dynamicBullets: false,
                clickable: true
            }}
            // Disable preloading of all images
            preloadImages={false}
            // Enable lazy loading
            lazy={{
                loadPrevNext: true,
                preloaderClass: "preloader-skeleton"
            }}
            autoplay={{
                delay: 5000,
                disableOnInteraction: false
            }}
            // scrollbar={{draggable: false}}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
            className={classes.bannerSlider}
        >
            {_map(props.data, (item, index) => {
                return <SwiperSlide key={index}>
                    {index === 0
                        ? <BannerCardBlueWeeks/>
                        : <BannerCard
                            to={_get(item, "attributes.url", '')}
                            title={_get(item, "attributes.title", '')}
                            content={_get(item, "attributes.content", '')}
                            image={_get(item, "attributes.slide_image", '')}
                        />
                    }
                </SwiperSlide>
            })}
        </Swiper>
    );
};

const useStyles = makeStyles((theme) => ({

        bannerSlider: {

            "& .swiper-pagination": {
                lineHeight: 1,
                fontSize: 0
            },
            "&.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet ": {
                height: 4,
                width: 88,
                borderRadius: 2,
                display: "inline-block",
                verticalAlign: "middle",
                background: "#141414",
                /*opacity: 0.2;*/
                opacity: 1,
                margin: "0 12px",
            },
            "&.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet-active": {
                background: "#fff"
            },
            "& .swiper-pagination-bullets": {
                bottom: "60px",
                [theme.breakpoints.down('sm')]: {
                    bottom: "10px",
                }
            },
            [theme.breakpoints.down('sm')]: {
            backgroundColor:"#141414"
            }
        },


    }))
;
