import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Fade from "react-reveal/Fade";
import _isEmpty from "lodash/isEmpty";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';

import SectionBox from "../../components/SectionBox";
import BlueWeeksSlider from "../../components/sliders/BlueWeeksSlider";
import {fetchBestPriceByModel} from "../../actions/products";
import CountdownBlueWeeks from "../../components/BlueWeeks/Countdown";
import {makeStyles} from "@material-ui/core/styles";
import {appStyle} from "../../theme/appStyle";

/**
 * This slider block has flex grow set to 1
 * This means it will occupy as much width and height available, if possible
 */
function GetOfferSliderParent({loader = false}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const bestPriceData = useSelector(state => state.bestPrice.data);
    const bestPriceLoading = useSelector(state => state.bestPrice.request);
    const showLoader  = loader === false
        ? false
        : bestPriceLoading;

    useEffect(() => {
        if (_isEmpty(bestPriceData)) {
            dispatch(fetchBestPriceByModel())
        }
    }, [])

    return (
        <React.Fragment>
            {showLoader
                ? <Box minHeight={"25rem"} height={"100%"} bgcolor="primary.dark" flexGrow={1}></Box>
                : <>{!_isEmpty(bestPriceData) &&
                <Box bgcolor="primary.dark" flexGrow={1}>
                    <SectionBox>
                        <Fade>
                            <Container maxWidth={"sm"}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box pb={6}>
                                            <Typography
                                                variant="h2"
                                                component="h2"
                                                align={"center"}
                                                className={` ${classes.pageTitle} ${classes.whiteText} ${classes.weight500}`}
                                            >
                                                Vezi și alte oferte Volvo Blue Weeks
                                            </Typography>

                                            <Grid  container justify={"center"}>
                                                <CountdownBlueWeeks/>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Fade>
                        <Fade>
                            <Container>
                                <BlueWeeksSlider data={bestPriceData}/>
                            </Container>
                        </Fade>
                    </SectionBox>
                </Box>
            }</>
            }
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
}))
export default GetOfferSliderParent;
