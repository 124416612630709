import React, {useEffect, useState} from 'react'
import clsx from "clsx";
import ScrollAgent from 'react-scroll-agent';
import {useInView} from 'react-intersection-observer';
import {Sticky, StickyContainer} from 'react-sticky';
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from "react-router";
import Fade from "react-reveal/Fade";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import _map from "lodash/map";
import ReactHtmlParser from "react-html-parser";

import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Fab from '@material-ui/core/Fab';
import Icon from "@material-ui/core/Icon";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import {PAGES_SLUGS} from "../../constants";

import * as actions from "./actions"
import {fetchPage} from "../../actions/pages"
import {getWishlistData, WL} from "../../helpers/localStorage";
import {ShareUrl} from "../../helpers/shareURL";
import {addAccessoryToWishlist, removeAccessoryFromWishlist} from "../WishlistPage/actions";

import SectionBox from "../../components/SectionBox";
import {Title} from "../../components/Typografy";
import TabsWidget from "../../components/TabsWidget";
import ExpandableSections from "../../components/ExpandableSections";
import ProgressFullScreen from "../../components/ProgressFullScreen";
import Seo from "../../components/seo/Seo";
import Footnotes from "../../components/Footnotes";
import AccessoryDetailsSlider from "../../components/accessories/AccessoryDetailsSlider";
import RelatedAccessoriesSlider from "../../components/accessories/RelatedAccessories";
import itemDescriptionStyles from "../../theme/itemDescriptionStyles";
import itemDetailsStyles from "../../theme/itemDetailsStyles";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import EquipmentDataCard from "../../components/EquipmentDataCard";

function AccessoryDetailsPage() {
    const dispatch = useDispatch();
    const {slug} = useParams();
    const classes = useStyles();

    const accessory = useSelector(state => _get(state.accessoryDetails.data, slug, {}));
    const loading = useSelector(state => state.accessoryDetails.request);
    const updatingWishlist = useSelector(state => state.wishlist.request);
    const [open, setOpen] = useState(false);
    const [openRemove, setOpenRemove] = useState(false);
    const pageData = useSelector(state => _get(state.pages.data, PAGES_SLUGS.ACCESSORY_DETAIL, {}));
    const {ref, inView, entry} = useInView({
        /* Optional options */
        threshold: 0,
    });

    const disclaimerData = _get(pageData, "data.disclaimer_section.disclaimer_section", []);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (_isEmpty(accessory)) {
            dispatch(actions.fetchAccessory(slug))
        }
    }, [slug])


    useEffect(() => {
        if (_isEmpty(pageData)) {
            dispatch(fetchPage(PAGES_SLUGS.ACCESSORY_DETAIL))
        }
    }, [])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setOpenRemove(false);
    };

    const addToWishlist = () => {
        setOpenRemove(false);
        dispatch(addAccessoryToWishlist(slug));
        setOpen(true);
    }

    const removeFromWishlist = () => {
        setOpen(false);
        dispatch(removeAccessoryFromWishlist(slug));
        setOpenRemove(true);
    }

    const renderDisclaimerNotes = () => {
        return _map(disclaimerData, (item, index) => {
            return <Typography key={index} gutterBottom variant="caption" component={"div"}>
                {ReactHtmlParser(_get(item, "attributes.text", ""))}
            </Typography>
        })
    }
    const handleGoTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const dataForSectionWidget = useSelector(state => {
        if (!_isEmpty(accessory)) {
            return [
                {"name": "",
                    "data": [{
                        "name": _get(accessory, "long_description", [])
                    }
                    ]
                },
                {"name": "Avantaje",
                    "data": [{
                        "name":  _get(accessory, "avantaje", [])
                    }
                    ]
                },
                {"name": "Componente",
                    "data": _get(accessory, "component_list", []).map((acc) => {
                        return {"name": `${_get(acc, "CodExtern", '')} ${_get(acc, "Descriere", '')}`}

                    })
                },
            ]
        }

        return [];
    });

    return (
        <React.Fragment>
            {!(loading || _isEmpty(accessory)) && <section className={clsx(classes.root, "submenuScrollarea")}>
                <Seo data={{
                    meta_og_title: _get(accessory, "base_name", ''),
                    meta_title: _get(accessory, "base_name", ''),
                    meta_image: _get(accessory, "photos[0].thumb[1]", ''),
                }}/>

                <Snackbar open={open} onClose={handleClose}
                          autoHideDuration={2000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          className={classes.customLowerSnackbar}
                >
                    <Alert
                        onClose={handleClose}
                        action={''}
                        icon={false}
                        className={`${classes.customAlert} ${classes.alertSuccess}`}
                    >
                        ✓ Adaugat cu succes in wish list
                    </Alert>
                </Snackbar>
                <Snackbar open={openRemove} onClose={handleClose}
                          autoHideDuration={2000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          className={classes.customLowerSnackbar}
                >
                    <Alert
                        onClose={handleClose}
                        action={''}
                        icon={false}
                        className={`${classes.customAlert} ${classes.alertSuccess}`}
                    >
                        <Icon className={clsx("brand-icon-remove")}/> Sters cu succes in wish list
                    </Alert>
                </Snackbar>

                <div ref={ref} className={classes.almostWhiteBackground} >
                    <AccessoryDetailsSlider data={accessory} pageData={pageData}/>
                </div>
                <StickyContainer>
                    <ScrollAgent
                        nav={({current, positions}) => (
                            <Sticky topOffset={-100}>
                                {props => (
                                    <div style={{...props.style, top: 100, zIndex: 2, background: `#141414`}}>
                                        <TabsWidget
                                            current={current}
                                            positions={positions}
                                            labels={["Descriere", "Avantaje", "Componente", "Note"]}
                                        />
                                    </div>
                                )}
                            </Sticky>
                        )}
                        detectEnd={false}
                        /*Changes anchors to all elements that have this attribute, not just section*/
                        selector="[data-scrollspy]"
                        threshold="center"
                    >

                        <SectionBox gutterBottom={false}>
                            <Container>
                                {!_isEmpty(dataForSectionWidget) &&
                                    <ExpandableSections cta={"Descriere"}>
                                        <Fade>
                                            {_map(dataForSectionWidget, (item, index) => {
                                                return <EquipmentDataCard
                                                    key={index}
                                                    name={item.name}
                                                    data={item.data}
                                                    accessoryPage={true}
                                                />
                                            })}
                                        </Fade>
                                    </ExpandableSections>}
                            </Container>
                        </SectionBox>

                        <SectionBox gutterBottom={false}>
                            <Container data-scrollspy>
                                <ExpandableSections cta={"Note"}>
                                    <Fade>
                                        <>
                                            {!_isEmpty(_get(accessory, "note", '')) &&
                                            <Grid container>
                                                <Grid item xs={1}>
                                                    <Typography align={"right"} component={"p"}>
                                                        <Icon color={"primary"} className={`brand-icon-info ${classes.infoIcon}`}/>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <Grid item xs={12} className={classes.sectionBox}>
                                                        <div className={classes.noteText} style={{whiteSpace: `pre-wrap`, overflowWrap: `break-word`}}>
                                                            {accessory.note.replaceAll('\\n', '\n')}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            }
                                            {/*Because we have a top anchor, we will render a generic text if the object is empty*/}
                                            {!_isEmpty(_get(accessory, "compatibility_models", {})) &&
                                                <CompatibilityWidget data={accessory.compatibility_models}/>
                                            }
                                        </>
                                    </Fade>
                                </ExpandableSections>
                            </Container>
                        </SectionBox>

                        <SectionBox gutterTop={false}>
                            <Fade>
                                <Container>
                                    <Divider/>
                                    <Box display={"flex"} flexWrap={"wrap"} justifyContent={"center"} alignItems={"center"}>
                                        {getWishlistData(WL.ACCESSORY).includes(slug)
                                            ? <ButtonBase
                                                focusRipple
                                                component={Link}
                                                className={classes.underlineLinkButton}
                                                onClick={removeFromWishlist}
                                                disabled={updatingWishlist}
                                            >
                                                <Box display={"flex"} alignItems={"center"} className={classes.buttonItem}>
                                                    <Icon className={clsx("brand-icon-heart-fill", classes.icon)}/>
                                                    Sterge din wish list
                                                    {updatingWishlist &&
                                                    <CircularProgress size={24} className={classes.buttonProgress}/>}
                                                </Box>
                                            </ButtonBase>
                                            : <ButtonBase
                                                focusRipple
                                                component={Link}
                                                className={classes.underlineLinkButton}
                                                onClick={addToWishlist}
                                                disabled={updatingWishlist}
                                            >
                                                <Box display={"flex"} alignItems={"center"} className={classes.buttonItem}>
                                                    <Icon className={clsx("brand-icon-heart", classes.icon)}/>
                                                    Wish list
                                                    {updatingWishlist &&
                                                    <CircularProgress size={24} className={classes.buttonProgress}/>}
                                                </Box>
                                            </ButtonBase>
                                        }

                                        <ShareUrl
                                            title={_get(accessory, 'base_name', '')}
                                            text={_get(accessory, 'short_description', 'Descopera cele mai bune oferte ale momomentului pentru modelul Volvo preferat')}
                                        >
                                            <ButtonBase
                                                focusRipple
                                                component={Link}
                                                className={classes.underlineLinkButton}
                                            >
                                                <Box display={"flex"} alignItems={"center"} className={classes.buttonItem}>
                                                    <Icon className={clsx("brand-icon-share", classes.icon)}/>
                                                    Share accesoriu
                                                </Box>
                                            </ButtonBase>
                                        </ShareUrl>
                                    </Box>
                                    <Divider/>
                                </Container>
                            </Fade>
                        </SectionBox>

                        {!_isEmpty(_get(accessory, "similar_accessories", [])) && <SectionBox gutterBottom={false}>
                            <Fade>
                                <Container maxWidth={"sm"}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Title>Accesorii similare</Title>
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Container>
                                    <RelatedAccessoriesSlider data={_get(accessory, "similar_accessories", [])}/>
                                </Container>
                            </Fade>
                        </SectionBox>}
                    </ScrollAgent>
                </StickyContainer>

                {(!_isEmpty(disclaimerData)) && <SectionBox gutterTop={false}>
                    <Fade>
                        <Footnotes>
                            {renderDisclaimerNotes()}
                        </Footnotes>
                    </Fade>
                </SectionBox>}
            </section>}

            {(loading || _isEmpty(accessory)) &&
            <ProgressFullScreen loading={loading || _isEmpty(accessory)}/>
            }
            {!inView && <Fab
                aria-label={"Back to Top"}
                className={classes.fab}
                color={'secondary'}
                onClick={handleGoTop}
            >
                <ExpandLessIcon/>
            </Fab>}
        </React.Fragment>
    )
}

const CompatibilityWidget = (data) => {
    const classes = useStyles();
    if (!_isEmpty(data)) {
        return (
            <div className={classes.descriptionParent}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography component={"p"} className={classes.sectionTitle}>Compatibil</Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={10}>
                    {Object.entries(data.data).map(
                        ([key, value], index) => {
                        return <Grid key={index} item md={3} sm={4} xs={6} className={classes.sectionBox}>
                            <Typography component={"p"} className={classes.sectionText}>{key}</Typography>
                            <Typography component={"p"} className={classes.sectionName}>
                                {_map(value, (item, index) => {
                                    return <span key={index}>{item}{index !== (value.length - 1) && ','} </span>
                                })}
                            </Typography>
                        </Grid>
                    })}
                </Grid>
            </div>
        )
    }
}

const useStyles = makeStyles((theme) => ({
    ...itemDescriptionStyles(theme),
    ...itemDetailsStyles(theme),
    root: {
        background: "#fff",
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },

    // Acc page
    almostWhiteBackground: {
        backgroundColor: `#fafafa`
    },
    sectionBox: {
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    infoIcon: {
        marginRight: theme.spacing(3),
    },
    noteText: {
        padding: theme.spacing(3),
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(24),
        backgroundColor: `#fafafa`,
        border: `1px solid #eee`,
        borderRadius: theme.spacing(1)
    },
    sectionText: {
        ...itemDescriptionStyles(theme).sectionText,
        paddingBottom: theme.spacing(1),
    },
    sectionName: {
        ...itemDescriptionStyles(theme).sectionName,
        paddingBottom: theme.spacing(0),
    }
}))

export default AccessoryDetailsPage;
