import React, {useState} from 'react'
import clsx from "clsx";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import _has from "lodash/has";

import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';

import Icon from "@material-ui/core/Icon";
import toLocale from "../../helpers/toLocale";
import Skeleton from '@material-ui/lab/Skeleton';
import getSuppotedFileType from "../../helpers/getSuppotedFileType";
import {appStyle} from "../../theme/appStyle";
import itemDetailsStyles from "../../theme/itemDetailsStyles";

const GetOfferCardBlueWeeks = (props) => {
    const {data} = props;
    const classes = useProductCardStyles();
    const [loading, setLoading] = useState(true);

    const onLoad = () => {
        setLoading(false);
    }

    const showDiscountPrice = Boolean(data.client_invoice_price) && data.client_invoice_price !== data.total_car_price;

    return(
        <div className={classes.productCardRoot}>
            {!_isEmpty(_get(data, "photos", [])) && <div className={clsx(classes.productImage)}>
                <img
                    src={getSuppotedFileType(_get(data, "photos[0].thumb", []))}
                    className={classes.image}
                    onLoad={onLoad}
                />
                {loading && <Skeleton variant="rect" width={"100%"} height={207}/>}
            </div>
            }
            <Typography
                className={`${classes.sku} ${classes.colorBlueGrey}`}
                component="p"
            >
                # {_get(data, 'rom_ref', '')}
            </Typography>
            <Typography
                variant="h1"
                component="h1"
                className={`${classes.title} ${classes.whiteText}`}
            >
                {_get(data, 'name', '')}
            </Typography>
            <div className={classes.optionsRoot}>
                <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"}>
                    {_has(data, "fuel") && <Box display={"flex"} alignItems={"center"} className={classes.optionItem}>
                        <Icon className={clsx("brand-icon-fuel", `${classes.icon} ${classes.whiteText}`)}/>
                        <Typography className={classes.colorBlueGrey} variant="caption">{_get(data, "fuel", '')}</Typography>
                    </Box>}
                    {_has(data, "transmission_type") &&
                        <Box display={"flex"} alignItems={"center"} className={classes.optionItem}>
                            <Icon className={clsx("brand-icon-transmission", `${classes.icon} ${classes.whiteText}`)}/>
                            <Typography className={classes.colorBlueGrey} variant="caption">{_get(data, "transmission_type", '')}</Typography>
                        </Box>}
                    {_has(data, "transmission") && <Box display={"flex"} alignItems={"center"} className={classes.optionItem}>
                        <Icon className={clsx("brand-icon-traction", `${classes.icon} ${classes.whiteText}`)}/>
                        <Typography className={classes.colorBlueGrey} variant="caption">{_get(data, "transmission", '')}</Typography>
                    </Box>}

                </Box>
            </div>

            <Box className={classes.priceParent} display={"flex"} pb={3}>
                <Typography
                    component={"div"}
                    className={`${classes.rootPrice}`}>
                    Pret final:&nbsp;
                    <span className={`${classes.price} ${classes.whiteText}`}>{toLocale(_get(data, 'client_invoice_price', ''))}€ + TVA</span>
                    {showDiscountPrice &&
                        <div className={`${classes.oldPrice} ${classes.colorBlueGrey}`}>
                            Redus de la:&nbsp;
                            <span className={classes.lineThrough}>
                            {toLocale(_get(data, 'total_car_price', ''))}€
                        </span> + TVA
                        </div>
                    }
                </Typography>

                <Box display={"flex"} flexDirection={"column"} alignItems={"start"}>
                    {_get(data, "discount_percentage", false) &&
                        <div className={`${classes.discountChip}`}>
                            -{toLocale(data.discount_percentage)}%
                        </div>
                    }
                    <div className={`${classes.blueChip}`}>Blue Weeks</div>
                </Box>
            </Box>
        </div>
    )
}

const useProductCardStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    ...itemDetailsStyles(theme),
    productCardRoot: {
        backgroundColor: theme.palette.primary.dark,
        padding: theme.spacing(3),
        flex: 1,
        borderRadius: 4
    },
    productImage: {
        paddingBottom: theme.spacing(3),
    },
    image: {
        maxWidth: "100%",
    },
    sku: {
        fontSize: '14px',
        fontWeight: '300',
        letterSpacing: 0,
        lineHeight: '24px',
        paddingBottom: theme.spacing(2),

    },
    title: {
        coloe: "#141414",
        letterSpacing: "0.4px",
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(26),
        lineHeight: theme.typography.pxToRem(32),
        paddingBottom: theme.spacing(0),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(20),
            lineHeight: theme.typography.pxToRem(32),
        },
    },
    priceParent: {
      gap: theme.spacing(2),
        "@media (max-width: 419.98px)": {
            flexDirection: "column"
        },
    },
    rootPrice: {
        ...itemDetailsStyles(theme).rootPrice,
        paddingBottom: 0,
        color: `#C0C9D6`
    },
    price: {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(24),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(16),
        },
    },
    oldPrice: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(24),
        paddingTop: theme.spacing(1)
    },
    lineThrough: {
        textDecoration: "line-through",
    },
    optionsRoot: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    icon: {
        fontSize: "20px",
        marginRight: theme.spacing(2),
    },
    optionItem: {
        marginRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
    },
    sectionTitle: {
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    blueChip: {
        ...appStyle(theme).chipBase,
        background: `#2F5790`,
        marginLeft: 0
    },
    discountChip: {
        ...appStyle(theme).chipBase,
        background: `#9E2B2B`,
        marginBottom: theme.spacing(1),
        marginLeft: 0
    },
}))
export default GetOfferCardBlueWeeks;
