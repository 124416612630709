import React, {Component} from 'react';
/*ui*/
import {withStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import {appStyle} from "../../theme/appStyle";

const customStyles = theme => ({
    ...appStyle(theme),
    countdownParentItem: {
        "@media (max-width: 419.98px)": {
            width: `100%`,
        },
    },
    countdownContainer: {
        gap: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            gap: theme.spacing(2),
        },
        "@media (max-width: 419.98px)": {
            '& > div':{
                flex: 1,
            }
        },
    },
    countdownBox: {
        backgroundColor: '#294F86',
        width: `100%`,
        height: theme.spacing(8),
        borderRadius: theme.spacing(0.5),
        "@media (min-width: 420px)": {
            width: theme.spacing(10),
        },
    },
});

/**
 * Grid item with 100% width
 */
class CountdownBlueWeeks extends Component {
    state = {
        timeRemaining: {
            days: "--",
            hours: "--",
            minutes: "--",
            seconds: "--"
        },
    };
    interval = null;

    componentDidMount() {
        this.startCountdown();
    }

    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval);
    }

    startCountdown = () => {
        const targetDate = new Date("November 30, 2024 23:59:59").getTime();

        this.interval = setInterval(() => {
            const now = new Date().getTime();
            const difference = targetDate - now;

            if (difference <= 0) {
                clearInterval(this.interval);
                this.setState({ timeRemaining: { days: "--", hours: "--", minutes: "--", seconds: "--" } });
            } else {
                this.setState({
                    timeRemaining: {
                        days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(2, '0'),
                        hours: String(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0'),
                        minutes: String(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0'),
                        seconds: String(Math.floor((difference % (1000 * 60)) / 1000)).padStart(2, '0'),
                    },
                });
            }
        }, 1000);
    };

    render() {
        const {classes} = this.props
        return (
            <Grid item className={classes.countdownParentItem} >
                <Grid container direction={"row"} className={classes.countdownContainer}>
                    <Grid item>
                        <Grid container className={`${classes.countdownBox}`} direction={"column"} justify={"center"}>
                            <Typography className={`${classes.fontSize24} ${classes.whiteText}`} align={"center"}>
                                {this.state.timeRemaining.days}
                            </Typography>
                            <Typography className={`${classes.colorBlueGrey}`} variant={"caption"} align={"center"}>
                                <span>ZILE</span>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container className={`${classes.countdownBox}`} direction={"column"} justify={"center"}>
                            <Typography className={`${classes.fontSize24} ${classes.whiteText}`} align={"center"}>
                                {this.state.timeRemaining.hours}
                            </Typography>
                            <Typography className={`${classes.colorBlueGrey}`} variant={"caption"} align={"center"}>
                                <span>ORE</span>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container className={`${classes.countdownBox}`} direction={"column"} justify={"center"}>
                            <Typography className={`${classes.fontSize24} ${classes.whiteText}`} align={"center"}>
                                {this.state.timeRemaining.minutes}
                            </Typography>
                            <Typography className={`${classes.colorBlueGrey}`} variant={"caption"} align={"center"}>
                                <span>MINUTE</span>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container className={`${classes.countdownBox}`} direction={"column"} justify={"center"}>
                            <Typography className={`${classes.fontSize24} ${classes.whiteText}`} align={"center"}>
                                {this.state.timeRemaining.seconds}
                            </Typography>
                            <Typography className={`${classes.colorBlueGrey}`} variant={"caption"} align={"center"}>
                                <span>SECUNDE</span>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    };
}

CountdownBlueWeeks = withStyles(customStyles)(CountdownBlueWeeks);

export default CountdownBlueWeeks;
