import React, {useState} from 'react';
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import _split from "lodash/split";
import _filter from "lodash/filter";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import placeholder from "../../images/placeholder.jpg";
import _map from "lodash/map";
import _slice from "lodash/slice";
import {Collapse} from "react-collapse";
import Link from "@material-ui/core/Link";
import Icon from "@material-ui/core/Icon";
import clsx from "clsx";
import getSuppotedFileType from "../helpers/getSuppotedFileType";

const PacheteCard = (props) => {
    const classes = useStyles();
    const {data} = props;
    const [isOpened, setIsOpened] = useState(false);

    const handleToggle = (event) => {
        event.preventDefault();
        setIsOpened(!isOpened);
    }

    const getImage = () => {
        if (!_isEmpty(_get(data, "photos", []))) {
            return _get(data.photos[0], "thumb", '');
        }
        return null
    }

    const renderToogleText = () => {
        if (isOpened) {
            return <Link href={"#"} onClick={handleToggle} className={classes.link}>
                <span className={classes.cta}>Mai putin</span>
                <Icon className={clsx("brand-icon-up-arrow", classes.icon)}/>
            </Link>
        } else {
            return <Link href={"#"} onClick={handleToggle} className={classes.link}>
                <span className={classes.cta}>Mai mult</span>
                <Icon className={clsx("brand-icon-down-arrow", classes.icon)}/>
            </Link>
        }
    }

    const renderDescription = () => {
        let descArr = _split(data.description, ";");

        descArr = _filter(descArr, (item) => {
            return item != "";
        });

        return <>
            {renderSampleData(descArr)}
            {renderColapsedeData(descArr)}
        </>
    }

    const renderSampleData = (data) => {
        if (!_isEmpty(data)) {
            return _map(_slice(data, 0, 3), (item, index) => {
                return <Typography key={index} variant={"body1"} component={"p"}>{item}</Typography>
            })
        }
    }

    const renderColapsedeData = (data) => {
        if (!_isEmpty(data)) {
            if (data.length > 3) {
                return <>
                    <Collapse isOpened={isOpened} theme={{collapse: classes.collapse, content: classes.content}}>
                        {_map(_slice(data, 3, data.length), (item, index) => {
                            return <Typography key={index} variant={"body1"} component={"p"}>{item}</Typography>
                        })}
                    </Collapse>
                    <Grid item xs={12} className={classes.sectionBox}>
                        {renderToogleText()}
                    </Grid>
                </>
            }
        }
    }


    return <Grid item xs={12} sm={6} md={3}>
        <div
            className={classes.imageWrapper}
        >
            {!_isEmpty(_get(data, "photos[0].thumb", [])) ?
                <img src={getSuppotedFileType(_get(data, "photos[0].thumb", []))} className={classes.image}/>
                : <img src={placeholder} className={classes.image}/>}
        </div>
        <div className={classes.body}>
            <div>
                <Typography paragraph className={classes.title} align={"left"}>{data.name}</Typography>
            </div>
            <div className={classes.text}>
                {renderDescription()}
            </div>
        </div>

    </Grid>
}

const useStyles = makeStyles((theme) => ({
    imageWrapper: {
        width: "100%",
        paddingTop: "58.39%",
        marginBottom: theme.spacing(3),
        position: "relative",
    },
    image: {
        objectFit: "cover",
        objectPosition: '50% 50%',
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
    },

    body: {},

    title: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(22),
        lineHeight: theme.typography.pxToRem(32),
        marginBottom: theme.spacing(3),
    },
    text: {
        "& p": {
            marginBottom: theme.spacing(2),
            wordBreak: "break-word",
        }
    },
    content: {
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
    },
    collapse: {
        width: "100%",
        transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    cta: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(24),
    },
    icon: {
        fontSize: "14px",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
        color: theme.palette.primary.main,
    },
    link: {
        display: "inline-flex",
        alignItems: "center",
        position: 'relative',
        textDecoration: "none",
        "&:hover": {
            textDecoration: "none",
        },
        "&:before": {
            content: `''`,
            position: "absolute",
            width: "0",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            background: theme.palette.primary.main,
            transition: theme.transitions.create(['width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        "&:hover:before": {
            content: `''`,
            position: "absolute",
            width: "100%",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            background: theme.palette.primary.main
        }
    },

}));
export default PacheteCard;
