/*CONSTANTS*/
export const DEFAULT_LOCALE = `ro-RO`;

export const PAGES_SLUGS = {
    "HOMEPAGE": "home",
    "PRODUCTS": "cars",
    "BLUE_WEEK": "blue-weeks",
    "ACCESSORIES": "accessories",
    "ACCESSORIES_INTRO": "accessories-intro",
    "CAR_DETAILS": "car-details",
    "DEALER": "dealer",
    "DETAILS": "details",
    "ACCESSORY_DETAIL": "accessory-detail",
    "OFFERS": "cere-oferta",
    "ACCESSORY_OFFER": "cere-oferta-accesoriu",
    "ACCESSORIES_OFFERS": "cere-oferta-accesorii",
    "ABOUT_US": "despre",
    "FINANCE": "finantare",
    "DEALERSHIPS": "dealerships",
    "EXTENDED_WARRANTY": "garantie-extinsa",
    "TERMS_AND_CONDITIONS": "termeni-si-conditii",
    "TESTDRIVE_THANK_YOU": "test-drive-thank-you",
    "OFFER_THANK_YOU": "cere-oferta-thank-you",
    "COOKIE_POLICY": "cookies",
    "CONFIDENTIALITY_POLICY": "politicadeconfidentialitate",
    "WISHLIST": "wish-list"
}
